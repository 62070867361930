import _ from 'lodash'
import type { IUnfinishedTasks, IViewerHandlers } from './types'
import { UNFINISHED_TASKS, VIEWER_HANDLERS_FACTORY } from './moduleNames'

const UnfinishedTasksFactory = ({ viewerHandlers }: IViewerHandlers): IUnfinishedTasks => {
	const { unfinishedTasks } = viewerHandlers

	return {
		add: (name) => {
			const id = _.uniqueId()

			unfinishedTasks.add(id, name)

			return () => {
				unfinishedTasks.remove(id)
			}
		},
	}
}

export default {
	factory: UnfinishedTasksFactory,
	deps: [VIEWER_HANDLERS_FACTORY],
	name: UNFINISHED_TASKS,
}
